import React from "react"
import { PageProps, graphql } from "gatsby"
import Helmet from "react-helmet"

import { SEOnoindex } from "src/components/seo-noindex"
import { Carousel } from "src/components/carousel"
import { VideoLoopResponsive } from "src/components/video-responsive"
import { ImgFluid } from "src/components/img-fluid"

import {
  HeadlineAndParagraph,
  HeaderHero,
  CaseStudyCapabilities,
} from "src/components/layouts"

import {
  Container,
  Block,
  Grid,
  Col,
  HorizontalRuler,
  Headline,
  Paragraph,
  ParagraphTall,
  HeadlineHero,
} from "src/components/primitives"

import { useLightTheme } from "src/context/theme"

type DataProps = {
  project: {
    seo: {
      title: string
      description: string
      image: {
        publicURL: string
      }
    }
    copy: {
      title: string
      subtitle: string
      intro: string
      exclusive_title: string
      exclusive_copy: string
      exclusive_2_copy: string 
      unique_title: string
      unique_copy: string
      supreme_title: string
      supreme_copy: string
      expertise: string

    }
    images: {
      hero_video_static: any
      criteria_diagram_static: any
      idea_cards_static: any
      grid_1_brand_static: any
      grid_1_tags_static: any
      grid_1_aura_static: any
      grid_1_hifi_static: any
      grid_1_smartfolder_static: any
      grid_1_logo_static: any
      sizzle_full_poster: any
      supremium_slides_01: any
      supremium_slides_02: any
      supremium_slides_03: any
      supremium_slides_04: any
      supremium_slides_05: any
    }
    videos: {
      hero_video: any
      criteria_diagram: any
      idea_cards: any
      grid_1_tags: any
      grid_1_aura: any
      grid_1_hifi: any
      grid_1_smartfolder: any
      grid_1_logo: any
      sizzle_full: any
      
    }
  }
}

const CaseStudy: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { seo, copy, images, videos } = data.project

  useLightTheme()

  return (
    <article aria-label={copy.title}>
      <Helmet
        bodyAttributes={{
          class: "theme-light",
        }}
      />
      <SEOnoindex
        title={seo.title}
        description={seo.description}
        image={seo.image.publicURL}
      />
      <Container>
        <HeaderHero>
          <HeadlineHero>
            {copy.title}
            <span>{copy.subtitle}</span>
          </HeadlineHero>
        </HeaderHero>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
            video={videos.hero_video}
            image={images.hero_video_static}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>{copy.intro}</Paragraph>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.exclusive_title}</Headline>
          <Paragraph>{copy.exclusive_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap>
            <Col>
              <VideoLoopResponsive
                video={videos.criteria_diagram}
                image={images.criteria_diagram_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                video={videos.idea_cards}
                image={images.idea_cards_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <Grid gap columns={{ bp4: "$12" }}>
          <Col start={{ bp4: "$6" }} end={{ bp4: "$13" }}>
            <ParagraphTall>{copy.exclusive_2_copy}</ParagraphTall>
          </Col>
        </Grid>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <HeadlineAndParagraph>
          <Headline>{copy.unique_title}</Headline>
          <Paragraph>{copy.unique_copy}</Paragraph>
        </HeadlineAndParagraph>
      </Container>

      <Container>
        <Block>
          <Grid gap columns={{ bp0: "$1", bp1: "$2" }}>
            <Col>
              <ImgFluid image={images.grid_1_brand_static} />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio={.75}
                video={videos.grid_1_tags}
                image={images.grid_1_tags_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_1_aura}
                image={images.grid_1_aura_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio="$3/4"
                video={videos.grid_1_hifi}
                image={images.grid_1_hifi_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio={.75}
                video={videos.grid_1_smartfolder}
                image={images.grid_1_smartfolder_static}
              />
            </Col>
            <Col>
              <VideoLoopResponsive
                ratio={.75}
                video={videos.grid_1_logo}
                image={images.grid_1_logo_static}
              />
            </Col>
          </Grid>
        </Block>
      </Container>

      <Container>
        <HorizontalRuler />
      </Container>

      <Container>
        <Headline>
          <Headline>{copy.supreme_title}</Headline>
        </Headline>
      </Container>

      <Container bleed>
        <Block>
          <VideoLoopResponsive
             ratio="$16/9"
             video={videos.sizzle_full}
             image={images.sizzle_full_poster}
          />
        </Block>
      </Container>

      <Container>
        <Paragraph>
          <Paragraph>{copy.supreme_copy}</Paragraph>
        </Paragraph>
      </Container>

      <Container>
        <Block> 
          <Col>
            <Carousel
              images={[
                images.supremium_slides_01,
                images.supremium_slides_02,
                images.supremium_slides_03,
                images.supremium_slides_04,
                images.supremium_slides_05,
              ]}
            />
          </Col>
        </Block>
      </Container>

      <Container>
        <CaseStudyCapabilities>{copy.expertise}</CaseStudyCapabilities>
      </Container>
    </article>
  )
}

export default CaseStudy

export const pageQuery = graphql`
  query {
    project: workJson(id: { eq: "spotify-supremium" }) {
      id
      seo {
        title
        description
        image {
          publicURL
        }
      }
      copy {
        title
        subtitle
        intro
        exclusive_title
        exclusive_copy
        exclusive_2_copy 
        unique_title
        unique_copy
        supreme_title
        supreme_copy
        expertise
      }
      images {
        hero_video_static {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        criteria_diagram_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        idea_cards_static {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        grid_1_brand_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_tags_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_aura_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_hifi_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_smartfolder_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        grid_1_logo_static {
          alt
          src {
            childImageSharp {
              ...HalfColumnImageFragment
            }
          }
        }
        sizzle_full_poster {
          alt
          src {
            childImageSharp {
              ...HeroImageFragment
            }
          }
        }
        supremium_slides_01 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        supremium_slides_02 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        supremium_slides_03 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        supremium_slides_04 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        supremium_slides_05 {
          alt
          src {
            childImageSharp {
              ...FullWidthImageFragment
            }
          }
        }
        

      }
      videos {
        hero_video {
          src {
            src_large
            src_medium
            src_small
          }
        }
        criteria_diagram {
          src {
            src_large
            src_medium
            src_small
          }
        }
        idea_cards {
          src {
            src_large
            src_medium
            src_small
          }
        }
        grid_1_tags {
          src
        }
        grid_1_aura {
          src
        }
        grid_1_hifi {
          src
        }
        grid_1_smartfolder {
          src
        }
        grid_1_logo {
          src
        }
        sizzle_full {
            src
          }
        

      }
    }
  }
`
